import React from 'react';

function AnotherComponent() {
  return (
    <div>
      <h2>This is Another Component</h2>
      <p>Welcome to the new component!</p>
    </div>
  );
}

export default AnotherComponent;
