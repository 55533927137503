import React, { useState, useEffect } from 'react';

function MyComponent() {
  const [userID, setUserID] = useState(null);
  const [data, setData] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 

  useEffect(() => {
    // Access the global wpUserID variable set by the WordPress shortcode
    const wpUserID = window.wpUserID || null;

    if (wpUserID) {
      setUserID(wpUserID);

      // Example API endpoint (replace with your actual API endpoint)
      const apiUrl = `https://jsonplaceholder.typicode.com/posts/${wpUserID}`;

      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } else {
      setError(new Error('User ID not found'));
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      <h1>User ID: {userID}</h1>
      {data && (
        <>
          <h2>{data.title}</h2>
          <p>{data.body}</p>
        </>
      )}
    </div>
  );
}

export default MyComponent;
