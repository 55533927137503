import logo from './logo.svg';
import './App.css';
import MyComponent from './MyComponent';
import AnotherComponent  from './AnotherComponent';

function App() {
  return (
    <div className="App">
      <MyComponent />
      <AnotherComponent />
    </div>
  );
}

export default App;
